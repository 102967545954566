<template>
    <div class="page_msg container">
      <div class="header_gruop page_width flex_sb">
        <div class="icon_home flex_center" @click="toBack()"><svg t="1729563443244" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6080" id="mx_n_1729563443245" width="16" height="16"><path d="M398.912 513.152l377.28-384.256c27.904-28.416 27.904-74.432 0-102.784-27.84-28.352-73.024-28.352-100.8 0L247.808 461.504C233.792 475.776 226.88 494.464 226.944 513.152 226.88 531.84 233.792 550.464 247.808 564.736l427.584 435.392c27.84 28.352 73.024 28.352 100.8 0 27.904-28.352 27.904-74.368 0-102.656L398.912 513.152z" fill="#16181c" p-id="6081"></path></svg></div>
        <div class="header_title">Refund Policy</div>
        <div></div>
      </div>
  
      <div class="scoller grid_content" :v-infinite-scroll="false" style="overflow:auto">
          <div class="service page_width">
              
              <p style="font-size: 18px;font-weight: bold;margin-bottom:20px;">Refund Policy </p>
              <!-- <p style="margin-bottom:10px;">Return policy</p> -->
              <p style="margin-bottom:10px;">Any game top up that have been credited is final.</p>
              <p >If customer have entered the wrong login credentials/player id to top up, Playsavefirst may notify customer via </p>
              <p >Customer-service@playsavefirst.com</p>
              <p style="margin-bottom:10px;"> for correction, but once the order is delivered, no refunds can be given.</p>
              <p style="margin-bottom:10px;">Bonus top up is given by the game authority, no refunds can be given if bonus top up is not credited to your account.</p>
              <p style="margin-bottom:10px;">Playsavefirst shall not be liable for any gift cards or other digital goods that has been stolen,misplaced or misused without authorization. For the avoidance of doubt, Playsavefirst shall not be obliged to enquire or verify at the redemption of the Gift Card, if such Gift Card has been properly obtained.</p>
              <p style="margin-bottom:10px;">Any form of CD Key, Gift Card, Game Points, Prepaid Card purchases is neither refundable or exchangeable.</p>
              <p style="margin-bottom:10px;">To be eligible for a return:Unauthorized charged on your credit/debit card, Paypal or any other payment methods and your order must not be delivered</p>
              <p style="margin-bottom:10px;">We are unable to fulfill your order due to the lack of stock or unable to deliver your items due to the item is not available anymore.</p>
              <p >To complete your return, please contact our Customer Support Live-Chat or through email to </p>
              <p >Customer-service@playsavefirst.com</p>
              <p style="margin-bottom:10px;"> . We require a receipt or proof of purchase in Playsavefirst for the payment to be refunded.</p>
              <p style="margin-bottom:10px;">Refunds (if applicable):If your purchase is eligible for refunds, please contact our Customer Support through email to Customer-service@playsavefirst.com</p>
              <p style="margin-bottom:10px;">Late or missing refunds (if applicable):If you haven’t received a refund yet, please check your payment accounts again.</p>
              <p style="margin-bottom:10px;">Then contact your credit card company, it may take some time before your refund is officially posted.</p>
              <p style="margin-bottom:10px;">Next contact your bank. There is often some processing time before a refund is posted.</p>
              <p >If you’ve done all of this and you still have not received your refund yet, please contact us at </p>
              <p style="margin-bottom:10px;">Customer-service@playsavefirst.com</p>
              <p >Exchanges (if applicable):We only replace items if they are defective or damaged.  If you need to exchange it for the same item, please contact our Customer Support through email to </p>
              <p style="margin-bottom:10px;">Customer-service@playsavefirst.com</p>
             
            
            
          </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
      methods:{
          toBack(){
              this.$router.back()
          }
      }
  }
  </script>
  
  <style>
  
  </style> 